import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Button as ButtonR } from 'rebass';

import { Icon } from '../icon';

const ButtonVariant = {
  Primary: 'primary',
  Secondary: 'secondary',
  Danger: 'danger',
};

const ButtonSize = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
};

const Button = forwardRef(
  (
    {
      isDisabled,
      isLoading,
      isUppercase = true,
      onClick,
      size = ButtonSize.MD,
      sx,
      text,
      variant = ButtonVariant.Primary,
      type = 'button',
      ...props
    },
    ref
  ) => {
    const setButtonSize = () => {
      switch (size) {
        case ButtonSize.MD:
          return {
            fontSize: 'md',
            height: 'control',
            minWidth: 150,
          };
        case ButtonSize.SM:
          return {
            fontSize: 'sm',
            height: 45,
            minWidth: 130,
          };
        case ButtonSize.XS:
          return {
            fontSize: 'xs',
            height: 30,
            minWidth: 100,
          };
        default:
          console.error(`Unknown button size ${size}.`);
          return {};
      }
    };

    const isVoid = isDisabled || isLoading;

    return (
      <ButtonR
        {...props}
        type={type}
        disabled={isVoid}
        onClick={() => !isVoid && onClick?.()}
        ref={ref}
        sx={{
          ...sx,
          ...setButtonSize(),
          textTransform: isUppercase ? 'uppercase' : 'normal',
          padding: '0 3rem',
        }}
        variant={variant}
      >
        {isLoading ? <Icon icon="spinner" /> : text}
      </ButtonR>
    );
  }
);

Button.propTypes = {
  text: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(ButtonVariant)),
};

export default Button;
