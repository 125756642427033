import React from 'react';

const IconPhone = (props) => (
  <svg
    {...props}
    width="55"
    height="91"
    viewBox="0 0 55 91"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M43.9375 0.125H10.9375C5.245 0.125 0.625 4.745 0.625 10.4375V80.5625C0.625 86.255 5.245 90.875 10.9375 90.875H43.9375C49.63 90.875 54.25 86.255 54.25 80.5625V10.4375C54.25 4.745 49.63 0.125 43.9375 0.125ZM27.4375 86.75C24.0138 86.75 21.25 83.9863 21.25 80.5625C21.25 77.1387 24.0138 74.375 27.4375 74.375C30.8612 74.375 33.625 77.1387 33.625 80.5625C33.625 83.9863 30.8612 86.75 27.4375 86.75ZM46 70.25H8.875V12.5H46V70.25Z"
      fill="black"
    />
  </svg>
);

export default IconPhone;
