export const ButtonStyleBase = {
  ':focus': { outline: 'none' },

  paddingTop: '0',
  paddingBottom: '0',
  paddingLeft: 'lg',
  paddingRight: 'lg',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  fontFamily: 'primary',
  fontStyle: 'normal',
  fontWeight: 'normal',
  letterSpacing: 'normal',
  lineSpacing: 'normal',

  borderWidth: 'md',
  borderStyle: 'solid',
  borderRadius: 'md',

  transition: '300ms',
  cursor: 'pointer',
};

export const ButtonStyle = {
  buttons: {
    primary: {
      ...ButtonStyleBase,
      color: 'primaryText',
      backgroundColor: 'primary',
      borderColor: 'primary',
      ':hover': {
        borderColor: 'primaryLight',
        backgroundColor: 'primaryLight',
      },
    },
    secondary: {
      ...ButtonStyleBase,
      color: 'black',
      backgroundColor: 'base',
      borderColor: 'base',
      ':hover': {
        borderColor: 'baseDark',
        backgroundColor: 'baseDark',
      },
    },
    danger: {
      ...ButtonStyleBase,
      color: 'errorText',
      backgroundColor: 'error',
      borderColor: 'error',
      ':hover': {
        borderColor: 'errorLight',
        backgroundColor: 'errorLight',
      },
    },
  },
};
