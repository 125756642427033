import React from 'react';
import { ThemeProvider as ThemeProviderET } from 'emotion-theming';

import { getTheme } from './theme';

const ThemeProvider = ({ element }) => (
  <ThemeProviderET theme={getTheme('light')}>{element}</ThemeProviderET>
);

export default ThemeProvider;
