import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'rebass';

import { Icon } from '../icon';

const ButtonIconVariant = {
  Primary: 'primary',
  Secondary: 'secondary',
};

const ButtonIcon = forwardRef(
  ({ icon, sx, variant = ButtonIconVariant.Primary, ...props }, ref) => (
    <Button
      {...props}
      ref={ref}
      sx={{
        ...sx,
        variant: `buttonIcon.${variant}`,
      }}
    >
      <Icon
        icon={icon}
        color={variant === ButtonIconVariant.Primary ? 'primaryText' : 'dark'}
      />
    </Button>
  )
);

ButtonIcon.propTypes = {
  icon: PropTypes.string,
};

export default ButtonIcon;
