import React from 'react';

const IconPhoneChecked = (props) => (
  <svg
    {...props}
    width="194"
    height="194"
    viewBox="0 0 194 194"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_45_3721)">
      <path
        d="M124.875 8.08325H60.2083C49.0533 8.08325 40 17.1366 40 28.2916V165.708C40 176.863 49.0533 185.917 60.2083 185.917H124.875C136.03 185.917 145.083 176.863 145.083 165.708V28.2916C145.083 17.1366 136.03 8.08325 124.875 8.08325ZM92.5417 177.833C85.8325 177.833 80.4167 172.417 80.4167 165.708C80.4167 158.999 85.8325 153.583 92.5417 153.583C99.2508 153.583 104.667 158.999 104.667 165.708C104.667 172.417 99.2508 177.833 92.5417 177.833ZM128.917 145.5H56.1667V32.3333H128.917V145.5Z"
        fill="black"
      />
      <path
        d="M84.4379 103.703L72.6517 91.9172L68.6382 95.9025L84.4379 111.702L118.355 77.7851L114.37 73.7998L84.4379 103.703Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_45_3721">
        <rect width="194" height="194" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconPhoneChecked;
