import { ButtonStyle } from './Button.theme';

const ButtonIconBaseStyle = {
  width: 'control',
  height: 'control',
  border: '0',
  borderRadius: '50px',
  minWidth: 'unset',
  padding: 0,
};

export const ButtonIconStyle = {
  buttonIcon: {
    primary: {
      ...ButtonStyle.buttons.primary,
      ...ButtonIconBaseStyle,
      backgroundColor: 'primary',
      '> svg': {
        color: 'primaryText',
      },
      ':hover': {
        borderColor: 'transparent',
        backgroundColor: 'transparent',
      },
    },
    secondary: {
      ...ButtonStyle.buttons.secondary,
      ...ButtonIconBaseStyle,
      backgroundColor: 'transparent',
      '> svg': {
        color: 'black',
      },
      ':hover': {
        borderColor: 'transparent',
        backgroundColor: 'transparent',
      },
    },
  },
};
