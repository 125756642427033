import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import IconArrowLeft from './IconArrowLeft';
import IconCalendar from './IconCalendar';
import IconChevronDown from './IconChevronDown';
import IconClipboardCheck from './IconClipboardCheck';
import IconClose from './IconClose';
import IconDotsVertical from './IconDotsVertical';
import IconEdit from './IconEdit';
import IconEmail from './IconEmail';
import IconError from './IconError';
import IconFlag from './IconFlag';
import IconInfo from './IconInfo';
import IconMenu from './IconMenu';
import IconPhone from './IconPhone';
import IconPhoneChecked from './IconPhoneChecked';
import IconPlus from './IconPlus';
import IconSearch from './IconSearch';
import IconSend from './IconSend';
import IconSettings from './IconSettings';
import IconSpinner from './IconSpinner';
import IconTime from './IconTime';

const IconType = {
  ArrowLeft: 'arrow-left',
  Calendar: 'calendar',
  ChevronDown: 'down',
  ClipboardCheck: 'clipboard-check',
  Close: 'close',
  DotsVertical: 'dots-vertical',
  Edit: 'edit',
  Email: 'email',
  Error: 'error',
  Flag: 'flag',
  Info: 'info',
  Menu: 'menu',
  Phone: 'phone',
  PhoneChecked: 'phone-checked',
  Plus: 'plus',
  Search: 'search',
  Send: 'send',
  Settings: 'settings',
  Spinner: 'spinner',
  Time: 'time',
};

const IconSize = {
  LG: 'lg',
  MD: 'md',
  SM: 'sm',
};

const IconColor = {
  Dark: 'dark',
  Important: 'important',
  Light: 'light',
  PrimaryText: 'primaryText',
};

const Icon = ({
  color = IconColor.Dark,
  customWidth,
  icon,
  size = IconSize.MD,
  ...props
}) => {
  const renderIcon = () => {
    switch (icon) {
      case IconType.ChevronDown:
        return <IconChevronDown />;
      case IconType.Close:
        return <IconClose />;
      case IconType.Error:
        return <IconError />;
      case IconType.Plus:
        return <IconPlus />;
      case IconType.Search:
        return <IconSearch />;
      case IconType.Send:
        return <IconSend />;
      case IconType.Spinner:
        return <IconSpinner />;
      case IconType.Edit:
        return <IconEdit />;
      case IconType.Email:
        return <IconEmail />;
      case IconType.Info:
        return <IconInfo />;
      case IconType.ArrowLeft:
        return <IconArrowLeft />;
      case IconType.Time:
        return <IconTime />;
      case IconType.Settings:
        return <IconSettings />;
      case IconType.Calendar:
        return <IconCalendar />;
      case IconType.ClipboardCheck:
        return <IconClipboardCheck />;
      case IconType.Menu:
        return <IconMenu />;
      case IconType.DotsVertical:
        return <IconDotsVertical />;
      case IconType.Flag:
        return <IconFlag />;
      case IconType.Phone:
        return <IconPhone />;
      case IconType.PhoneChecked:
        return <IconPhoneChecked />;
      default:
        console.error(`Unknown icon ${icon}`);
        return null;
    }
  };

  return (
    <IconStyled {...props} customWidth={customWidth} size={size} color={color}>
      {renderIcon()}
    </IconStyled>
  );
};

export default Icon;

const IconStyled = styled.span`
  &&& {
    font-size: inherit;
    line-height: inherit;

    &&& svg {
      ${({ theme, size, customWidth }) => {
        const defaultStyle = css`
          width: ${customWidth || theme.fontSizes.lg};
          height: ${theme.fontSizes.lg};
          color: inherit;
          font-size: inherit;
          line-height: inherit;
        `;

        switch (size) {
          case IconSize.LG:
            return css`
              width: ${customWidth || '55px'};
              height: auto;
            `;
          case IconSize.MD:
            return defaultStyle;
          case IconSize.SM:
            return css`
              width: ${customWidth || theme.fontSizes.md};
              height: ${theme.fontSizes.md};
            `;
          default:
            console.error(`Unknown icon size ${size}`);
            return defaultStyle;
        }
      }}
    }

    color: ${({ theme, color }) => {
      switch (color) {
        case IconColor.Dark:
          return theme.colors.black;
        case IconColor.Light:
          return theme.colors.white;
        case IconColor.PrimaryText:
          return theme.colors.primaryText;
        case IconColor.Important:
          return theme.colors.importantDark;
        default:
          console.error(`Unknown icon color ${color}.`);
          return theme.colors.black;
      }
    }};
  }
`;
