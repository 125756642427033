import { ButtonStyle, ButtonIconStyle } from './components/ui/chat-ui/button';

export const ColorMode = {
  Light: 'light',
  Dark: 'dark',
};

const ColorLight = {
  // brand
  primary: '#2159f2',
  primaryDark: '#192d75',
  primaryLight: '#4073fd',
  primaryText: '#fff',
  // monochrome
  base: '#e6e6e6',
  baseAlpha: '#e6e6e68e',
  baseDark: '#c4c4c4',
  baseDark2: '#898989',
  baseDark3: '#545454',
  baseLight: '#f0f0f0',
  baseLight2: '#f8f8f8',
  baseLight3: '#a1a1a1',
  black: '#333',
  blackAlpha: '#33333380',
  white: '#fff',
  whiteAlpha: '#ffffff80',
  // status
  error: '#f33',
  errorDark: '#801a1a',
  errorLight: '#ff4747',
  errorText: '#fff',
  pending: '#959864',
  pendingDark: '#70724b',
  pendingLight: '#a7ab71',
  pendingText: '#fff',
  success: '#4fa86a',
  successDark: '#3f8655',
  successLight: '#72b988',
  successText: '#fff',
  important: '#fffb05',
  importantDark: '#ccc904',
  importantLight: '#fffb1e',
  importantText: '#333',
  // estimate page
  baseGray: '#f5f5f5',
  baseGray1: '#e5e5e5',
  baseGray2: '#757575',
  conditionRough: '#FF5449',
  justOkay: '#FFC000',
  excellent: '#00A576',
  pricing: '#094ee8',
};

const ColorDark = {
  // brand
  primary: '#2159f2',
  primaryDark: '#4073fd',
  primaryLight: '#192d75',
  primaryText: '#fff',
  // monochrome
  base: '#1c1c1c',
  baseAlpha: '#1c1c1c8e',
  baseDark: '#252525',
  baseDark2: '#2e2e2e',
  baseLight: '#121212',
  baseLight2: '#090909',
  black: '#fff',
  blackAlpha: '#ffffff80',
  white: '#333',
  whiteAlpha: '#33333380',
  // status
  error: '#f33',
  errorDark: '#ff4747',
  errorLight: '#801a1a',
  errorText: '#fff',
  pending: '#959864',
  pendingDark: '#a7ab71',
  pendingLight: '#70724b',
  pendingText: '#fff',
  success: '#4fa86a',
  successDark: '#72b988',
  successLight: '#3f8655',
  successText: '#fff',
  important: '#fffb05',
  importantDark: '#fffb1e',
  importantLight: '#ccc904',
  importantText: '#333',
  // estimate page
  baseGray: '#f5f5f5',
};

export const MediaBreakpoints = {
  xs: '478px',
  sm: '567px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
};

const SpacingNormal = {
  '2xs': '3px',
  xs: '6px',
  sm: '10px',
  md: '14px',
  lg: '18px',
  xl: '30px',
  '2xl': '38px',
  '3xl': '46px',
  '4xl': '62px',
  '5xl': '74px',
};

const SpacingSmall = {
  '2xs': '3px',
  xs: '4px',
  sm: '6px',
  md: '10px',
  lg: '14px',
  xl: '24px',
  '2xl': '30px',
  '3xl': '36px',
  '4xl': '50px',
  '5xl': '60px',
};

export const getTheme = (colorMode = ColorMode.Light, hasSmallSpacing) => {
  const colors = colorMode === ColorMode.Dark ? ColorDark : ColorLight;
  const space = hasSmallSpacing ? SpacingSmall : SpacingNormal;
  return {
    colorMode,
    colors,
    space,
    fonts: {
      primary: 'Averta',
    },
    fontSizes: {
      '2xs': '8px',
      xs: '12px',
      sm: '14px',
      md: '16px',
      lg: '20px',
      xl: '28px',
      '2xl': '40px',
    },
    fontWeights: {
      normal: '400',
      semiBold: '600',
      bold: '700',
    },
    radii: {
      md: '10px',
      lg: '12px',
    },
    borderWidths: {
      md: '1px',
      lg: '10px',
    },
    sizes: {
      full: '100%',
      control: '50px',
    },
    shadows: {
      md: `${colors.blackAlpha} 0 1px 3px 0, ${colors.blackAlpha} 0 1px 2px 0`,
      lg: `0 3px 7px ${colors.blackAlpha}`,
    },
    mediaBreakpoints: MediaBreakpoints,
    ...ButtonStyle,
    ...ButtonIconStyle,
  };
};
